import React from 'react';
import { ReactComponent as VideoBoxButtonSvg} from './../svgs/videoBoxButton.svg';

import {
  Link
} from "react-router-dom";

import Header from "./header.jsx";
import Footer from "./footer.jsx";
import Fancybox from "./fancybox.jsx";
import SpeciesPie from "./SpeciesPie.jsx";

const toggleClassOnHover = (className) => {
  return function(e) {
    e.target
      .classList
      .toggle(className, e.type === 'mouseenter');
  }
}

function addHoverAction(el, className) {
  const toggleActionFunc = toggleClassOnHover(className);
  el.addEventListener('mouseenter', toggleActionFunc);
  el.addEventListener('mouseleave', toggleActionFunc);
}

function loadMain() {
  window.AOS.init();
  addHoverAction(document.getElementsByClassName("animated_samurai")[0], "animate__rubberBand");
  addHoverAction(document.getElementsByClassName("animated_logo")[0], "animate__rubberBand");
  addHoverAction(document.getElementsByClassName("join-btn")[0], "animate__headShake");
  addHoverAction(document.getElementsByClassName("footer__logo")[0], "animate__rubberBand");
};

function goToDapp() {
  window.open("https://dapp.battlespecies.net");
}

export default class Main extends React.Component {

  componentDidMount() {
    loadMain();
  }

  render() {
    return (
      <div id="app">
        <div className="line">
        </div>
        <div>
          <Header />
          <main className="main-screen" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-4">
                  <div className="main" data-aos="fade-right">
                    <div className="main__logo">
                      <img className="animated_logo animate__animated" width="200" alt="" title="" src="./static/media/battlespecies-sign.png" />
                    </div>
                    <p className="desc">Yield <b>TACHYON</b> daily when you become an exclusive member of BattleSpecies. With an Alpha-Species collection of 333 NFTs, 30 of which are the Elite, you’ll have access to all the content, avatars, and exciting new avenues developed with the brand. <b>TACHYON</b> is the utility token that fuels the BattleSpecies ecosystem – it is NOT an investment and has NO economic value. The battle starts here!
                    </p>
                    <div className="main__countdown">
                      <img className="animated_samurai animate__animated" width="185" height="240" alt="" title="" src="./static/media/bsc-samurai.png"/>
                      <button className="main__countdown-item1 btn l-primary-button__button btn-color btn-md btn-type-base btn-icon-out" onClick={goToDapp}>
                          <span>MINT HERE</span>
                      </button>
                      <span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-3 offset-xl-5">
                  <div className="main__lead" data-aos="fade-left">
                    <div className="fcards">
                      <div className="main__lead-actions">
                        <div className="howtobuy">
                          <Link to="howtobuy">
                            <span>
                              <i>How to Mint?
                              </i>
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="card fcard-1">
                        <div className="card-overlay">
                        </div>
                        <div className="card-item">
                          <div className="card-item__image">
                            <img src="./static/media/nfts/1.jpg" alt="" title="" className="img-responsive"/>
                          </div>
                          <div className="card-item__info">
                            <div className="card-item__info-row">
                              <div className="card-item__info-col1">
                                <span className="card-item__info-name">
                                </span>
                              </div>
                              <div className="card-item__info-col2">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card fcard-2">
                        <div className="card-overlay">
                        </div>
                        <div className="card-item">
                          <div className="card-item__image">
                            <img src="./static/media/nfts/2.jpg" alt="" title="" className="img-responsive" />
                          </div>
                          <div className="card-item__info">
                            <div className="card-item__info-row">
                              <div className="card-item__info-col1">
                                <span className="card-item__info-name">
                                </span>
                              </div>
                              <div className="card-item__info-col2">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card fcard-3">
                        <div className="card-overlay">
                        </div>
                        <div className="card-item">
                          <div className="card-item__image">
                            <img src="./static/media/nfts/3.jpg" alt="" title="" className="img-responsive" />
                          </div>
                          <div className="card-item__info">
                            <div className="card-item__info-row">
                              <div className="card-item__info-col1">
                                <span className="card-item__info-name">
                                </span>
                              </div>
                              <div className="card-item__info-col2">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main__video" data-aos="fade-down" data-delay="200">
                    <div className="video__box">
                      <div className="video__box-btn">
                        <Fancybox options={{ infinite: false }}>
                          <button href="#"
                            rel="noreferrer noopener"
                            className="btn-round"
                            data-fancybox=""
                            data-src="https://www.youtube.com/watch?v=yT0yj8VFcaQ&autoplay=1">
                            <VideoBoxButtonSvg />
                          </button>
                        </Fancybox>
                      </div>
                      <div className="video__box-item rotateAnimate">
                        <div className="video__box-round">
                          <img src="static/media/vid-spinner.png" height="312" width="312" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <section className="section-welcome">
          <div className="container aos-init" data-aos="fade-up">
            <div className="row aos-init" data-aos="fade-right">
              <div className="col-lg-4">
                <div className="pre-title">
                  <span>BattleSpecies
                  </span>
                </div>
                <h2 className="title">The Battle for The Multiverse Starts Now!
                </h2>
                <div className="desc">
                  <p className="">The fabric of space and time is a complicated thing. With a vast universe that’s constantly expanding beyond the realms of human understanding, it only adds up that there are infinite forms of reality, in which infinite outcomes can take place.
                  </p>
                  <p className="">The outcome we find ourselves in here is a bleak one. In a different realm of existence, many years in the future, aliens have arrived on earth, with a less-than diplomatic mission…
                  </p>
                  <p className="">Despite our world’s efforts, and combined military capacities, our primitive weaponry has not matched up to the advanced technology of these hostile alien conquerors.
                  </p>
                  <p className="">With the earth little more than a scorched battlefield and humans forced to retreat underground, hope seems all but lost, and the war seems all but won for the intergalactic invaders. But little do they know, humanity still has an ace left up its sleeve.
                  </p>
                  <p className="">When all seems lost, enters a unique band of superhuman heroes, determined to save the earth or go down fighting. Each with their own unique abilities and history, this group of veritable demi-gods are the only hope when it comes to facing up against the legions of alien soldiers that made such light work of humanity’s other resources.
                  </p>
                  <p className="">Across this series, they will face up against the aliens in many historic battles that involve showcases of brute strength, mystical abilities, and military strategy. As battles intensify the stakes will only get higher, threatening the fabric of space and time as we know it.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="welcome__img">
                  <img alt="" title="" className="img-responsive" src="./static/media/story-upfront.jpg" />
                </div>
              </div>
            </div>
            <div className="row aos-init align-items-center types-of-species" data-aos="fade-right">
              <div className="col-lg-8">
                <div className="chartPadding" data-aos="fade-right" data-aos-easing="ease-in-sine" data-delay="400">
								  <SpeciesPie />
                </div>
              </div>
              <div className="col-lg-4">
                <h2 className="title">Types of Species and Their Utilities
                </h2>
                <ul className="species_types desc">
                  <li className="aos-init" data-aos="zoom-in-down" data-delay="300">
                    <h2 className="species-title-elite">Elite Alpha-Species</h2> These are the first 30 of 333. They have the same capability as Alpha-Species, but they have more trait rarity, so they are more valuable. Unlike the rest of the Alpha-Species, these will be reserved by the BattleSpecies team and held for auction at a later date.
                  </li>
                  <li className="aos-init" data-aos="zoom-in-down" data-delay="600">
                    <h2 className="species-title-alpha">Alpha-Species</h2>  These are the first 333. Not only are they badass, but they are the only BattleSpecies to Yield TACHYON daily. To earn TACHYON daily for the next ten years, you must hold one of these.
                  </li>
                  <li className="aos-init" data-aos="zoom-in-down" data-delay="900">
                    <h2 className="species-title-sigma">Sigma-Species (Hyperion)</h2> These are from the next collection that's soon-to-be released. The Sigma Species manifest in parallel universes to conquer the multiverse. To obtain a Sigma-Species, your TACHYON is needed to power the Quantum Dark Beam which generates a wormhole. This process requires the NFT holder to own at least two Alpha-Species. When the wormhole is generated, a Portal is unveiled. Here, an unrevealed Sigma-Species provides a connection to the multiverse through the wormhole. These Portals are tradeable, just like other BattleSpecies NFTs, and the Portal must be “manifested” to reveal the newly minted Sigma-Species.
                  </li>
                  <li className="aos-init" data-aos="zoom-in-down" data-delay="1200">
                    <h2 className="species-title-portal">Portals</h2> These are newly minted Sigma-Species that are unrevealed and transferable.
                  </li>
                  <li className="aos-init" data-aos="zoom-in-down" data-delay="1500">
                    <h2 className="species-title-omega">Omega-Species</h2>  Into the multiverse and beyond –  BattleSpecies NFT holders will decide this path together as a governance community. Furthermore, holders will be able to vote by spending TACHYON.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row align-items-center aos-init" data-aos="fade-left">
              <div className="col-lg-4 order-2 order-lg-1">
                <div className="pre-title">
                  <span>BECOME A PART OF BATTLESPECIES NETWORK
                  </span>
                </div>
                <h2 className="title">ENJOY OUR COMIC BOOKS, MOTION COMICS AND FUTURE GAMES
                </h2>
                <div className="desc">At Blue Chip Comics, we’re obsessed with everything NFT comic books. Whether drawing influence from Japanese manga, Marvel and DC superheroes, or grittier graphic novels, we simply love using images and words to tell stories that transport us to alternative dimensions.</div>
                <div className="desc">While we have published physical and digital comic books in the past, it’s clear that more people are choosing to read comics online. Despite our love for the classic format of paper and ink – we know it’s important to evolve with our readers. However, we’re not satisfied with being just another online subscription service.</div>
                <div className="desc">That’s why we’re taking the step into the world of NFT comics with our first flagship franchise, BattleSpecies. With this unique mashup of superhero versus fighting game and sci-fi genres, we’re creating a fresh and exciting series of the best sci-fi comics for the enjoyment of those lucky enough to get involved now.</div>
              </div>
              <div className="col-lg-8 order-1 order-lg-2">
                <div className="welcome__img welcome__img--right">
                  <img alt="" title="" className="img-responsive" src="./static/media/become-a-part-01.png"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-own" id="collection">
          <div className="container aos-init" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-8">
                <div className="own__img">
                  <img className="img-responsive" alt="" title="" src="./static/media/bg-nft-01.png" />
                  <div className="cards__pos-button">
                    <a href="https://opensea.io/collection/battlespecies" target="_blank" rel="noreferrer noopener">
                      <button className="btn-round">
                        <span>Buy on OpenSea
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3 offset-xl-1">
                <div className="pre-title">
                  <span>The Battle Starts Here!
                  </span>
                </div>
                <h2 className="title">Your <span>BattleSpecies</span> NFT collection.
                </h2>
                <p className="desc">
                </p>
              </div>
            </div>
            <div className="cards">
              <div className="cards__pos cards__pos--1 aos-init" data-aos="flip-up">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/1.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards__pos cards__pos--2 aos-init" data-aos="flip-down">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/2.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards__pos cards__pos--3 aos-init" data-aos="flip-up">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/3.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards__pos cards__pos--4 aos-init" data-aos="flip-down">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/4.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cards__pos-button">
                  <a href="https://opensea.io/collection/battlespecies" target="_blank" rel="noreferrer noopener">
                    <button className="btn-round">
                      <span>Buy on OpenSea
                      </span>
                    </button>
                  </a>
                </div>
              </div>
              <div className="cards__pos cards__pos--5 aos-init" data-aos="flip-up">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/5.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards__pos cards__pos--6 aos-init" data-aos="flip-down">
                <div className="card undefined">
                  <div className="card-overlay">
                  </div>
                  <div className="card-item">
                    <div className="card-item__image">
                      <img src="./static/media/nfts/6.jpg" alt="" title="" className="img-responsive" />
                    </div>
                    <div className="card-item__info">
                      <div className="card-item__info-row">
                        <div className="card-item__info-col1">
                          <span className="card-item__info-name">
                          </span>
                        </div>
                        <div className="card-item__info-col2">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-benefits">
          <div className="section-benefits__bg aos-init" data-aos="fade-in" data-delay="300" data-ofsset="300">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="pre-title">
                    <span>
                    </span>
                  </div>
                  <h2 className="title">Why Become a Member?
                  </h2>
                  <div className="desc">
                    <p className="aos-init" data-aos="zoom-in-down" data-delay="300">Join the battle and be a part of the best digital comic book NFT projects and a governance community (soon to be released). You will be able to vote and help reconstruct our NFT comic club community by using or native TACHYON token – a utility token that yields daily for the next ten years.</p>
                    <p className="aos-init" data-aos="zoom-in-down" data-delay="600">By burning a certain amount of TACHYON, owners can give their ERC721 characteristics, such as a unique name and a biography. Better yet, owners can manifest one of the randomly generated Sigma Species with different traits and rarities.</p>
                    <p className="aos-init" data-aos="zoom-in-down" data-delay="900">We value readers just as much as comics themselves, which is why we’ve worked to create an exclusive, unique platform for consuming comic books and their related media. We’re doing more than just shifting comics – we’re building a worldwide online community.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-lg-2 col-xl-2 offset-xl-1 mr-fix-1">
                  <div className="benefit aos-init" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="200">
                    <div className="benefit__item">
                      <div className="benefit__item-img benefit__item-img--1">
                        <img width="48" height="48" alt="" title="" src="./static/media/eye_icon.png" />
                      </div>
                      <div className="benefit__item-title">First Look Access
                      </div>
                      <div className="benefit__item-desc">As a member of the BattleSpecies family, you’ll be first in line for all new content drops, whether that’s a new issue or a collection of community avatars.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-2 col-xl-2">
                  <div className="benefit aos-init" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="400">
                    <div className="benefit__item">
                      <div className="benefit__item-img benefit__item-img--2">
                        <img width="48" height="48" alt="" title="" src="./static/media/gamer_icon.png" />
                      </div>
                      <div className="benefit__item-title">Anime/Moving Comics
                      </div>
                      <div className="benefit__item-desc">Holding a BattleSpecies NFT will give you access to our multimedia franchise, including the moving comics and anime that we’re developing.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-2 offset-lg-2 col-xl-2 offset-xl-2">
                  <div className="benefit aos-init" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="600">
                    <div className="benefit__item">
                      <div className="benefit__item-img benefit__item-img--4">
                        <img width="48" height="48" alt="" title="" src="./static/media/unlock_icon.png" />
                      </div>
                      <div className="benefit__item-title">Other Online Content
                      </div>
                      <div className="benefit__item-desc">Along with all of our NFT comic books, exclusive avatars, and animation projects, we’re also working on exclusive online games and more!
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-2">
                  <div className="benefit aos-init" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="800">
                    <div className="benefit__item">
                      <div className="benefit__item-img benefit__item-img--4">
                        <img width="48" height="48" alt="" title="" src="./static/media/gift_icon.png" />
                      </div>
                      <div className="benefit__item-title">Random Gifts and Prizes
                      </div>
                      <div className="benefit__item-desc">What’s life without a few surprises? With your own BattleSpecies NFT, you’ll be part of a pool of members privy to exclusive random gifts.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-2 col-xl-2">
                  <div className="benefit benefit-last aos-init" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="800">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="roadmap" className="section-roadmap">
          <div className="container aos-init" data-aos="fade-in" data-delay="200" data-offset="200">
            <div className="row">
              <div className="col-lg-6 col-xl-7 d-flex flex-column justify-content-between">
                <div className="roadmap__img roadmap__img--1 aos-init" data-aos="fade-right" data-aos-easing="ease-in-sine" data-delay="200">
                  <img className="img-responsive" alt="" title="" src="./static/media/roadmap3.png" />
                </div>
                <div className="roadmaps-title aos-init" data-aos="fade-right" data-aos-easing="ease-in-sine" data-delay="200">
                  <div className="pre-title">roadmap
                  </div>
                  <h2 className="title">An ominous and 
                    <span className=""> monumental
                    </span> battle to save humanity
                  </h2>
                  <p className="desc">This is our roadmap to ensure that our vision and promises are fulfilled.
                  </p>
                </div>
                <div className="roadmap__img roadmap__img--2">
                  <img className="img-responsive" alt="" title="" src="./static/media/roadmap4.png" />
                </div>
                <div className="roadmap__img roadmap__img--2">
                  <img className="img-responsive" data-aos="fade-right" data-aos-easing="ease-in-sine" data-delay="200" alt="" title="" src="./static/media/roadmap5.png" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-5">
                <div className="roadmap-box">
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="300" data-offset="100">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 1: Our Journey Begins
                        <span className="">March 19, 2023
                        </span>
                      </div>
                      <span>
                        <p className="">
                          The battle started here! The BattleSpecies team worked meticulously to build a strategic approach for the NFT marketplace and the BattleSpecies community. Even after OpenSea changed the requirements for Royalty-fee eligibility, which prevented us from launching as previously projected. That didn't stop us.
                        </p>
                        <ul>
                          <li className="aos-init" data-aos="zoom-in-down" data-delay="300">We made distribution fair and transparent and ensured access to our valued membership holders with perks and rewards for years to come.
                          </li>
                          <li className="aos-init" data-aos="zoom-in-down" data-delay="600">As the project grows and matures, our loyal membership holders will grow with us.
                          </li>
                        </ul>
                      </span>
                    </div>
                  </div>
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="600">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 2: Public Sale and Reveal
                        <span className="">March 22nd, 2023
                        </span>
                      </div>
                      <span>
                        <p className="">Sales to the public were open for 40 MATIC. At the same time, whitelisted users were able to mint at a discounted rate of 20 MATIC. Unminted NFTs remained unrevealed along with the metadata to ensure a fair and level playing field for all future NFT minters. All newly-minted NFTs were automatically revealed after minting.
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="900">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 3: Collection Capping 
                        <span className="">April 5th, 2023
                        </span>
                      </div>
                      <span>
                        <p className="">Due to the low mint demand resulting from the NFT Bear market, we created a governance poll to gather consensus for capping the collection. This resulted in a unanimous decision to cap the collection earlier than expected. Everyone with at least 10 TACHYON (any holder at that time) had a chance to vote. The governance poll concluded by voting to cap the collection at 333. From there we allowed until May 1st for the "metadata freeze". Any NFT holder could claim rewards and Hyperwarp NFTs for the price of 600 TACHYON.
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="1200">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 4: Alpha-Species Collection Freeze
                        <span className="">May 1st, 2023
                        </span>
                      </div>
                      <span>
                        <p className="">We froze the metadata by uploading all NFT images and metadata to IPFS as well as ensuring the contract pointed to IPFS and <i>not</i> Web2 endpoints including Pinatas Gateway URL (most collections still do this). This protects the collection, and our Membership, from any future circumstances that would otherwise result in broken NFTs.</p>
                      </span>
                    </div>
                  </div>
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="1500">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 5: Sigma Collection
                        <span>(Near Future)
                        </span>
                      </div>
                      <span>
                        <p className="">
                        We plan on launching a store to allow the purchase of various BattleSpecies merchandise using TACHYON. A new Sigma collection will also be released which will allow Alpha-Species holders to Hyperwarp more NFTs from their TACHYON staking rewards.
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="roadmap aos-init" data-aos="zoom-in-down" data-delay="1800">
                    <div className="roadmap-icon">
                      <object width="48px" height="48px" data="static/media/bsc-energyball.svg">
                      </object>
                    </div>
                    <div className="roadmap-info">
                      <div>Phase 6: Evolve
                        <span>(Near Future)
                        </span>
                      </div>
                      <span>
                        <p className="">
                        To the multiverse and beyond: We will vote within the governance community to determine this path. It is a journey shared by all, so it is a journey we all must take part in!
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-team" id="team">
          <br />
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-lg-7 order-2 order-lg-1 relative">
                <div className="shad shad-1">
                  <span>
                  </span>
                  <div>nft project team
                  </div>
                </div>
                <div className="shad shad-2">
                  <span>
                  </span>
                  <div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-auto">
                    <div className="team">
                      <div className="team-card">
                        <div className="team-card-inner">
                          <div className="team-card__state">
                            <img src="./static/media/team/TamTran.jpg" alt="" title="" />
                          </div>                          
                          <div className="team-card__hover">
                            <img src="./static/media/team/TamTran.jpg" alt="" title="" />
                          </div>
                        </div>
                      </div>
                      <div className="team-bottom">
                        <div className="team-name">Tam 
                          <br />
                        </div>
                        <div className="team-info">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="team">
                      <div className="team-card">
                        <div className="team-card-inner">
                          <div className="team-card__state">
                            <img src="./static/media/team/QuinnNguyen.jpg" alt="" title="" />
                          </div>
                          <div className="team-card__hover">
                            <img src="./static/media/team/QuinnNguyen.jpg" alt="" title="" />
                          </div>
                        </div>
                      </div>
                      <div className="team-bottom">
                        <div className="team-name">Quinn Nguyen
                          <br />
                        </div>
                        <div className="team-info">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="team">
                      <div className="team-card">
                        <div className="team-card-inner">
                          <div className="team-card__state">
                            <img src="./static/media/team/PeterSu.jpg" alt="" title="" />
                          </div>
                          <div className="team-card__hover">
                            <img src="./static/media/team/PeterSu.jpg" alt="" title="" />
                          </div>
                        </div>
                      </div>
                      <div className="team-bottom">
                        <div className="team-name">Peter Su
                          <br />
                        </div>
                        <div className="team-info">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="team">
                      <div className="team-card">
                        <div className="team-card-inner">
                          <div className="team-card__state">
                            <img src="./static/media/team/Joe.jpg" alt="" title="" />
                          </div>
                          <div className="team-card__hover">
                            <img src="./static/media/team/Joe.jpg" alt="" title="" />
                          </div>
                        </div>
                      </div>
                      <div className="team-bottom">
                        <div className="team-name">Joe
                          <br />
                        </div>
                        <div className="team-info">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="team">
                      <div className="team-card">
                        <div className="team-card-inner">
                          <div className="team-card__state">
                            <img src="./static/media/team/KangLe.jpg" alt="" title="" />
                          </div>
                          <div className="team-card__hover">
                            <img src="./static/media/team/KangLe.jpg" alt="" title="" />
                          </div>
                        </div>
                      </div>
                      <div className="team-bottom">
                        <div className="team-name">Kang Le 
                          <br />
                        </div>
                        <div className="team-info">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg">
                    <p className="desc">We are a collaborative of creators committed to gripping the world with gorgeous visuals and impactful stories.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 order-1 order-lg-2">
                <div className="pre-title">
                  <span>team
                  </span>
                </div>
                <h2 className="title">We underpromise and overdeliver.
                </h2>
                <div className="partners">
                </div>
              </div>
            </div>
            <a href="/discord" target="_blank" rel="noreferrer noopener">
              <div id="joinDiscord" className="join-btn animate__animated">
                <span>Join Our Heroes To Save Humanity!
                </span>
              </div>
            </a>
          </div>
        </section>
        <Footer />
        <div className="Toastify">
        </div>
      </div>
    );
  }
}
