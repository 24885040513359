import {
  Link
} from "react-router-dom";

import Footer from "./footer.jsx";

export default function Contracts() {

		return (
			<div id="app">
				<div className="line"></div>
        <section className="contracts">
          <div className="container aos-init aos-animate" data-aos="fade-in" data-delay="200">
            <div className="pre-title">
              <Link className="pre-title-back" to="/">← Go to homepage</Link>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <h1 class="title">BattleSpecies Smart Contracts</h1>
              <br />
              <div class="textpage">
                <h2 class="title">BattleSpecies NFT</h2>
                <p>
                  <a href="https://polygonscan.com/address/0x65ab01008b1b16f2ab0f5a9ad850a8140f336060" target="_blank" rel="noreferrer noopener">
                    0x65Ab01008B1B16f2Ab0f5a9AD850a8140f336060
                  </a>
                </p>
                <h2 class="title">BattleSpecies Tachyon</h2>
                <p>
                  <a href="https://polygonscan.com/address/0x1f8ec14bfac580fa6b394e787771fbfb0aa2dbc3" target="_blank" rel="noreferrer noopener">
                    0x1f8EC14bfaC580FA6B394E787771fBFb0Aa2DbC3
                  </a>
                </p>
                <h2 class="title">BattleSpecies Utility Hub</h2>
                <p>
                  <a href="https://polygonscan.com/address/0x679b154bb4f8727b782fa10c5234aab521af8a55" target="_blank" rel="noreferrer noopener">
                    0x679b154bB4f8727B782fA10c5234aAb521af8A55
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
			</div>
		);
}
