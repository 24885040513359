import {
  Link
} from "react-router-dom";

import Footer from "./footer.jsx";

function goToDapp() {
    window.open("https://dapp.battlespecies.net");
}

export default function HowToMint() {

		return (
			<div id="app">
				<div className="line">
				</div>
          <section className="section-howtobuy">
            <div className="container aos-init aos-animate" data-aos="fade-in" data-delay="200">
              <div className="pre-title">
                <Link className="pre-title-back" to="/">← Go to homepage</Link>
              </div>
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <img className="animated_samurai animate__animated" width="185" height="240" alt="" title="" src="./static/media/bsc-samurai.png"/>
                  <button className="main__countdown-item1 btn l-primary-button__button btn-color btn-md btn-type-base btn-icon-out" onClick={goToDapp}>
                      <span>MINT HERE</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                  <h1 className="title">How to mint
                  </h1>
                  <div className="textpage">
                    <p className="">If you've never minted an NFT before, it can be a daunting process! We've put together a guide to help you navigate through the different steps you'll need to follow.
                    </p>
                    <p className="">
                    </p>
                    <h2 className="title">Preparing a Wallet
                    </h2>
                    <p className="">To mint an NFT on the Polygon blockchain, you'll need both MATIC and a wallet that can hold your NFT.
                    </p>
                    <p className="">The easiest way to do both at the same time is to download Metamask.</p>
                    <h2 className="title">Creating a Wallet
                    </h2>
                    <p className="">First, create an Polygon wallet that you can use to conduct your transactions and hold your NFTs. A few options that we support are Metamask, WalletConnect (which works for many other wallets), and Coinbase Wallet (which is different from a Coinbase.com account).
                    </p>
                    <p className="">Your Polygon wallet will have a public address on the Polygon network, which is where you can send and receive tokens like Polygon, Tachyon and our NFTs.
                    </p>
                    <h2 className="title">Buying MATIC
                    </h2>
                    <p className="">The easiest way to buy MATIC, the currency used on Polygon, is to use a crypto exchange.
                    </p>
                    <p className="">We recommend Coinbase for beginners. Note that this Coinbase.com account is different from a Coinbase Wallet account — it is an account for Coinbase's exchange, which allows you to trade currencies like USD for cryptocurrencies like MATIC.
                    </p>
                    <ol>
                      <li>Create an account and buy some MATIC.
                        <p className="">Make sure that you have more MATIC than you need for the cost of the mint — each Polygon transaction requires "gas," the network fees that go into processing blockchain transactions. Gas fees vary by the type of transaction and depending on the level of activity in the Polygon network, but minting an NFT can cost significantly more when there's high traffic. For example, the BATTLESPECIES cost 40 MATIC each to mint, so you may want to have some additional MATIC per BATTLESPECIES on hand.
                        </p>
                      </li>
                      <li className="">Once you've bought your MATIC, transfer it by sending it to the address of the wallet you just created earlier. Now you're ready to mint an NFT!
                      </li>
                    </ol>
                    <h2 className="title">Minting
                    </h2>
                    <p className="">When you "mint" an NFT, you're interacting with a smart contract on the blockchain and making a transaction to create a new token under that contract, one that you own. You can either mint through our <a href="https://dapp.battlespecies.net" target="_blank">website</a> (beginner-friendly) or directly through Polygonscan (more advanced).
                    </p>
                    <h2 className="title">Website (Beginner-Friendly)
                    </h2>
                    <p className="">The easiest way to mint BATTLESPECIES will be through the Battlespecies.net <a href="https://dapp.battlespecies.net" target="_blank">website</a>, since we'll handle a lot of the complexity for you.
                    </p>
                    <ol>
                      <li className="">You'll need to connect your wallet to our website using the "Connect Wallet" button — a modal will open, and you'll need to choose the wallet type you created earlier.
                      </li>
                      <li className="">Once your wallet is connected, select the number of BATTLESPECIES you'd like to mint, with a max of five (subject to change). Then click "Mint," and you should see an overview of the transaction you're about to create in your wallet, with the option to confirm it.
                      </li>
                      <li>In most cases, the transaction should take a minute or two and then complete. Voila, you've just minted your first NFT!
                        <p className="">Note: In some uncommon cases (i.e., the contract hits the total limit of BATTLESPECIES to mint), there is a possibility that your transaction will fail, which means that you will lose some or even all of the gas you paid. The reason that this scenario can happen is that any transaction on Polygon requires gas to execute — you always have to pay for the computations you're enacting on the network. Here are some common reasons transactions can fail.
                        </p>
                      </li>
                    </ol>
                    <h2 className="title">Polygonscan (Advanced)
                    </h2>
                    <p className="">If you'd like to interact directly with the smart contract, you can mint BATTLESPECIES on Polygonscan. Polygonscan only supports Metamask or WalletConnect, so Coinbase Wallet will not work for this method.
                    </p>
                    <ol>
                      <li className="">Go to our smart <a href="https://polygonscan.com/address/0x65ab01008b1b16f2ab0f5a9ad850a8140f336060#code" target="_blank">contract</a> in Polygonscan. It should have a green checkmark on the "Contract" tab to indicate that the source code is deployed and verified.
                      </li>
                      <li className="">You can click on the "Read Contract" button to check if the sale is active.
                      </li>
                      <li className="">If you're participating in the public sale, make sure that <a href="https://polygonscan.com/address/0x65ab01008b1b16f2ab0f5a9ad850a8140f336060#readContract#F27" target="_blank">saleIsActive</a> is set to true before you attempt to mint a BATTLESPECIES — otherwise, your transaction will be reverted, and you may lose gas.
                      </li>
                      <li className="">If you're participating in the whitelist sale, you will need to have already added your wallet address to the whitelist in order to mint a BATTLESPECIES. When using our <a href="https://dapp.battlespecies.net" target="_blank">dApp</a>, the Mint section will indicate whether or not your wallet address belongs to our whitelist.
                      </li>
                      <li className="">Now head over to the "Write Contract" view. Here, you'll need to connect your wallet to Polygonscan by clicking the "Connect to Web3" button. Once you're connected, you can start creating transactions under the smart contract.
                      </li>
                    </ol>
                    <h3 className="title">Now it's time to do the minting!
                    </h3>
                    <ol>
                      <li className="">If you're participating in the public sale, head to the function that just says mint. The price per BATTLESPECIES in the public sale is 40 MATIC, so multiply that by the number of BATTLESPECIES you want to mint. Enter that total in the payableAmount field, along with the number of BATTLESPECIES you want in the numberOfTokens field.
                      </li>
                      <li className="">If you're participating in the whitelist sale, you'll need to go to the function named mintWhitelist instead.
                      </li>
                      <li className="">The whitelist sale price per token is 20 MATIC (subject to change). Multiply that by the number of BATTLESPECIES you want to mint (up to three) and enter that total in the payableAmount field.
                      </li>
                      <li className="">Enter the number of BATTLESPECIES you want in the numberOfTokens field.
                      </li>
                      <li className="">The last field you'll need is your wlProof, which is how we verify that your address is on our list for this sale. You can retrieve your proof by requesting it in Discord. Copy the proof associated with your address and paste it into the merkleProof field in Polygonscan. 
                      </li>
                      <li className="">In most cases, the transaction should take a minute or two and then complete. And… now you’ve got your BATTLESPECIES.
                      </li>
                    </ol>
                    <h2 className="title">Viewing Your BATTLESPECIES Avatar
                    </h2>
                    <p className="">You should be able to see your BATTLESPECIES NFT shortly after minting! If you do not see it after several hours have passed, contact our support team.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        <Footer />
				<div className="Toastify">
				</div>
			</div>
		);
}
