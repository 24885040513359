import {
  Link
} from "react-router-dom";

import Footer from "./footer.jsx";

export default function ourErc20Token() {

		return (
      <div id="app">
        <div className="line">
        </div>
        <section className="section-tachyon">
          <div className="container aos-init aos-animate" data-aos="fade-in" data-delay="200">
            <div className="pre-title">
              <Link className="pre-title-back" to="/">← Go to homepage</Link>
            </div>
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <h1 className="title">TACHYON</h1>
                <div className="textpage">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2 className="title">What is TACHYON?</h2>
                      <p>TACHYON is what fuels the BattleSpecies economy, the multiverse, and our governance platform since it is the native utility token. As our multiverse grows, so will utility for our membership holders. We already provide multiple utilities for our membership holders to enjoy, and we believe we can make a difference as a team.</p>
                    </div>
                    <div className="col-lg-4">
                      <img className="img-responsive" alt="" title="" src="/static/media/tachyon.jpg" />
                    </div>
                  </div>
									<p>Our mission is to extend to the metaverse and beyond.</p>
									<h2 className="title">What is Yielding?</h2>
									<p>Yielding is a way to acquire TACHYON. This rare energy particle that can travel faster than the speed of light is extremely hard to find, and therefore, you must hold an Alpha-Species, enabling you to yield TACHYON daily for the next ten years.</p>
									<h2 className="title">What is Hyperwarping?</h2>
									<p>Hyperwarping is the process of generating a wormhole by spending TACHYON. TACHYON is required to power Quantum Dark Beams, which constructs a traversable wormhole that creates a Portal in a parallel universe. Portals are newly minted Sigma-Species that are unrevealed. To do this, NFT holders require 2 Alpha Species of any gender, 600 TACHYON, a separate blockchain transaction, and, in turn, gas fees.</p>
									<h2 className="title">What is Manifesting?</h2>
									<p>Manifesting is the process of revealing a Portal. This requires a separate blockchain transaction and, in turn, gas fees. Unlike Hyperwarping, Manifesting does not require any additional TACHYON to perform. The NFT Holder that Manifests the Portal does not have to be the same NFT holder that Hyperwarped. This encourages trading and commoditization of Portals. After the NFT holder Manifests the Portal, the Sigma-Species NFT character will be revealed.</p>
									<p>When these NFTs are transferred, so are their rewarded TACHYON.</p>
									<h2 className="title">Utility</h2>
									<ul>
										<li>Give it a cool name: 60 TACHYON</li>
										<li>Change the bio: 10 TACHYON</li>
										<li>Hyperwarping (requires 2 Alpha-Species): 600 TACHYON</li>
										<li>Voting: TACHYON is not only a utility token but also our governance token</li>
										<li>Future store purchases</li>
									</ul>
									<h2 className="title">Tokenomic Model</h2>
                  <img className="img-responsive" alt="" title="" src="/static/media/token-graph.jpg" />
									<p>We have devised a tokenomic model to ensure the stability and consistent value of the NFT project. As opposed to a static linear model, our logarithmic function decreases the amount of rewardable TACHYON over time. The amount of TACHYON rewarded daily is dependent on Alpha-Species holders claiming their rewards.</p>
									<p>Variability is built into the rewardable amount, meaning it is adjusted as a result of a decision made on our governance platform. This provides a mechanism to alter the model to protect the token from unforeseen circumstances.</p>
									<ul>
										<li>Tokens implement the ERC20 interface.</li>
										<li>Tokens must be claimed to be issued, which requires a GetReward transaction on the blockchain.</li>
										<li>Tokens will be burned based on NFT utility redemption.</li>
										<li>Rewardable tokens will decrease over time, incentivizing purchases closer to the launch date.</li>
										<li>Rewards allocated to specific Alpha-Species NFTs can be spent on utility functions for the Sigma Species (ENSURE POSSIBLE).</li>
										<li>The daily rate of earnable TACHYON will start at 10 and logarithmically decrease over time, diminishing to 5 by the first year and approaching 0 at the end of 10 years.</li>
										<li>All tokens will expire in 10 years.</li>
									</ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <div className="Toastify">
        </div>
      </div>
		);
}
