import React from "react";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Main from './components/main.jsx';
import Terms from './components/terms.jsx';
import HowToMint from './components/how-to-mint.jsx';
import OurErc20Token from './components/tachyon.jsx';
import ScrollToTop from './components/scrollToTop.jsx';
import Contracts from './components/contracts.jsx';
import './index.css';

import { createBrowserHistory } from 'history'

const history = createBrowserHistory();
const hash = history.location.hash;
if (hash) {
  const path = hash.replace(/^#/, '');
  if (path) {
    history.replace(path);
  }
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="terms" element={<Terms />} />
        <Route path="howtobuy" element={<HowToMint />} />
        <Route path="tachyon" element={<OurErc20Token />} />
        <Route path="contracts" element={<Contracts />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
