import 'chart.js/auto';
import React, { MouseEvent, useRef, useState } from 'react';
import type { InteractionItem } from 'chart.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';

import { 
	Chart,
	getElementAtEvent,
} from 'react-chartjs-2';

const data = {
  labels: [
    'Elite',
    'Alpha',
    'Sigma'
  ],
  datasets: [{
    label: 'BattleSpecies',
    data: [30, 970, 3500],
    backgroundColor: [
      'rgb(231, 87, 189)',
      'rgb(232, 233, 84)',
      'rgb(60, 0, 245)'
    ],
    hoverOffset: 4
  }]
};

const options = {
 interaction: { mode: 'index' },
 onHover: function (e) {
	 const points = this.getElementsAtEventForMode(
		 e,
		 'index', { axis: 'x', intersect: true },
		 false
	 );

	 if (points.length) e.native.target.style.cursor = 'pointer';
	 else e.native.target.style.cursor = 'default';
 }
};

ChartJS.register(ArcElement, Tooltip, Legend);

export default function SpeciesPie() {

  const getValuesAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];
    const labelName = data.labels[index];
    const labelValue = data.datasets[datasetIndex].data[index];
    return {
      labelName,
      labelValue
    }
  };

  const chartRef = useRef(null);
  const [source, setSource] = useState("");

  if (!source) setSource("./static/media/pie/Elite.jpg");

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }
    const {labelName} = getValuesAtEvent(getElementAtEvent(chart, event));
    if (labelName) {
      setSource(`./static/media/pie/${labelName}.jpg`);
    }
  };

  return (
    <div>
			<div className="card fcard-1">
				<div className="card-overlay">
				</div>
				<div className="card-item">
					<div className="card-item__image">
						<img src={source} alt="" title="" className="img-responsive"/>
					</div>
				</div>
			</div>
      <Chart
        ref={chartRef}
        type='pie'
        onClick={onClick}
        data={data} 
				options={options} />
    </div>
  );
}
