import React from "react";

import {
  Link
} from "react-router-dom";

import { ReactComponent as SocLinkSvg} from './../svgs/socLink.svg';
import { ReactComponent as SocLinkTwitterSvg} from './../svgs/socLinkTwitter.svg';
import { ReactComponent as SocLinkDiscordSvg} from './../svgs/socLinkDiscord.svg';
import { ReactComponent as SocLinkOpenseaSvg} from './../svgs/socLinkOpensea.svg';
import { ReactComponent as MenuBurgerSvg} from './../svgs/menuBurger.svg';
import { ReactComponent as MenuCloserSvg} from './../svgs/menuCloser.svg';

import MediaQuery from 'react-responsive';

import Fancybox from "./fancybox.jsx";

const findOffset = (element) => {
  var top = 0, left = 0;
  do {
    top += element.offsetTop  || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while(element);
  return {
    top,
    left,
  };
}

function goToSnapshot() {
  window.open("https://snapshot.org/#/battlespecies.eth");
}

function loadHeader() {

  const stickyHeader = document.getElementsByTagName("header")[0];
  const headerOffset = findOffset(stickyHeader);

  window.onscroll = function() {
    // body.scrollTop is deprecated and no longer available on Firefox
    const bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (bodyScrollTop > headerOffset.top + 1000) {
      stickyHeader.classList.add('header-fixed');
    } else {
      stickyHeader.classList.remove('header-fixed');
    }
  };
};

const MobileHeaderLinks = ({isOpened, handleMobileHeaderNavOnclick}) => {
  if (!isOpened) return;

  return(
    <MediaQuery maxWidth={991} >
      <div class="mobile__menu">
        <div class="container">
          <div className="mobile__menu-links">
            <Link className="mobile__menu-link" to="#roadmap" onClick={handleMobileHeaderNavOnclick}>Roadmap</Link>
            <Link to="/tachyon" className="mobile__menu-link" onClick={handleMobileHeaderNavOnclick}>Tachyon</Link>
            <Link className="mobile__menu-link" to="#collection" onClick={handleMobileHeaderNavOnclick}>Collection</Link>
            <Link className="mobile__menu-link" to="#team" onClick={handleMobileHeaderNavOnclick}>Team</Link>
            <Fancybox options={{ infinite: false }} onClick={handleMobileHeaderNavOnclick}>
              <a href="#"
                rel="noreferrer noopener"
                className="mobile__menu-link"
                data-fancybox=""
                data-src="https://www.youtube.com/watch?v=yT0yj8VFcaQ&autoplay=1">
                Trailer
              </a>
            </Fancybox>
            <Link to="/howtobuy" className="mobile__menu-link" onClick={handleMobileHeaderNavOnclick}>Mint</Link>	
            <Link to="/#" className="mobile__menu-link" onClick={goToSnapshot}>Vote</Link>
          </div>
        </div>
      </div>
    </MediaQuery>
  );
}

const MobileHeaderNav = ({handleMobileHeaderNavOnclick, isMobileHeaderLinksOpened}) => (
  <MediaQuery maxWidth={991}>
    <div className="col-12 col-lg-auto">
      <div className="header-info">
        <div className="header-mmenu">
          {isMobileHeaderLinksOpened ? <MenuCloserSvg onClick={handleMobileHeaderNavOnclick} /> : <MenuBurgerSvg onClick={handleMobileHeaderNavOnclick} />}
        </div>
        <div className="header-info__soc soc">
          <a href="https://www.instagram.com/tt321battlespecies/" target="_blank" rel="noreferrer noopener" className="soc-link">
            <SocLinkSvg />
          </a>
          <a href="https://twitter.com/battlespecies" target="_blank" rel="noreferrer noopener" className="soc-link">
            <SocLinkTwitterSvg />
          </a>
          <a href="/discord" target="_blank" rel="noreferrer noopener" id="joinDiscord" className="soc-link">
            <SocLinkDiscordSvg />
          </a>
          <a href="https://opensea.io/collection/battlespecies" target="_blank" rel="noreferrer noopener" className="soc-link">
            <SocLinkOpenseaSvg />
          </a>
        </div>
      </div>
    </div>
  </MediaQuery>
);

const DesktopHeaderNav = () => (
  <MediaQuery minWidth={992}>
  <div className="col-auto">
    <nav className="menu">
      <Link className="nav-link" to="#roadmap">Roadmap</Link>
      <Link to="/tachyon" className="nav-link">Tachyon</Link>
      <Link className="nav-link" to="#collection">Collection</Link>
      <Link className="nav-link" to="#team">Team</Link>
      <Fancybox options={{ infinite: false }}>
        <a href="#"
          rel="noreferrer noopener"
          className="nav-link"
          data-fancybox=""
          data-src="https://www.youtube.com/watch?v=yT0yj8VFca&amp;autoplay=1">
          Trailer
        </a>
      </Fancybox>
      <Link to="/howtobuy" className="nav-link">Mint</Link>
      <Link to="/#" className="nav-link" onClick={goToSnapshot}>Vote</Link>
    </nav>
  </div>
  <div className="col-12 col-lg-auto">
    <div className="header-info">
      <div className="header-info__soc soc">
        <a href="https://www.instagram.com/tt321battlespecies/" target="_blank" rel="noreferrer noopener" className="soc-link">
          <SocLinkSvg />
        </a>
        <a href="https://twitter.com/battlespecies" target="_blank" rel="noreferrer noopener" className="soc-link">
          <SocLinkTwitterSvg />
        </a>
        <a href="/discord" target="_blank" rel="noreferrer noopener" id="joinDiscord" className="soc-link">
          <SocLinkDiscordSvg />
        </a>
        <a href="https://opensea.io/collection/battlespecies" target="_blank" rel="noreferrer noopener" className="soc-link">
          <SocLinkOpenseaSvg />
        </a>
      </div>
    </div>
  </div>
  </MediaQuery>
);

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isMobileHeaderLinksOpened: false};
  } 

  componentDidMount() {
    loadHeader();
  }

  handleMobileHeaderNavOnclick() {
    this.setState({isMobileHeaderLinksOpened: !this.state.isMobileHeaderLinksOpened});
  }

  render() {
    return (
      <header className="header" data-aos="fade-up">
        <MobileHeaderLinks isOpened={this.state.isMobileHeaderLinksOpened} handleMobileHeaderNavOnclick={this.handleMobileHeaderNavOnclick.bind(this)} />
        <div className="container">
          <div className="menu__top">
            <div className="row justify-content-between align-items-center">
              <DesktopHeaderNav />
							<MobileHeaderNav handleMobileHeaderNavOnclick={this.handleMobileHeaderNavOnclick.bind(this)} isMobileHeaderLinksOpened={this.state.isMobileHeaderLinksOpened} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}
