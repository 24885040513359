import {
  Link
} from "react-router-dom";


export default function Footer() {

                  //<Link className="footer__links-item" to="/traits">Traits</Link>
                  //<Link className="footer__links-item" href="/contracts">Smart Contracts</Link>
                  //<Link className="footer__links-item" href="/ipfs">IPFS Storage</Link>

		return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-auto">
              <div className="footer__info">
                <img src="./static/media/bcc-token.png" alt="" title="" className="footer__logo animated_logo animate__animated" />
                <div className="footer__author">
                  <span>Project created by </span> 
                  <u>Blue Chip Comics</u>
                </div>
                <div className="footer__copy">© 2023 Battlespecies. All rights reserved.
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto ml">
              <div className="footer__links">
                <div className="footer__links-title">Docs
                </div>
                <div className="footer__links-items">
                  <Link className="footer__links-item" to="/tachyon">Tachyon</Link>
                  <Link className="footer__links-item" to="/terms">Terms &amp; Conditions</Link>
                  <Link className="footer__links-item" to="/contracts">Contracts</Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <div className="footer__links footer__links--ml13">
                <div className="footer__links-title">Follow
                </div>
                <div className="footer__links-items">
                  <a href="https://www.instagram.com/tt321battlespecies/" target="_blank" rel="noreferrer noopener" className="footer__links-item">Instagram
                  </a>
                  <a href="https://twitter.com/battlespecies" target="_blank" rel="noreferrer noopener" className="footer__links-item">Twitter
                  </a>
                  <a href="/discord" target="_blank" rel="noreferrer noopener" id="joinDiscord" className="footer__links-item">Discord
                  </a>
                  <a href="https://opensea.io/collection/battlespecies" target="_blank" rel="noreferrer noopener" className="footer__links-item">OpenSea
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
		);
}
