import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import App from './App';
import Terms from './components/terms.jsx';
import HowToMint from './components/how-to-mint.jsx';

import ScrollToTop from './components/scrollToTop.jsx';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
 
root.render(<App />);
/*
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="terms" element={<Terms />} />
      <Route path="howtobuy" element={<HowToMint />} />
    </Routes>
  </BrowserRouter>
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
